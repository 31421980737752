<template>
	<div>
		<s-crud
			title="Listado de Pallets a desinfectadas"
			:config="config"
			:filter="filter"
			height="auto"
			:group-by="'SplName'"
		></s-crud>
	</div>
</template>

<script>
	import _service from "@/services/FrozenProduction/DisinfectedPalletService";

	export default {
		data() {
			return {
				filter: {},
				config: {	
					service: _service,
					model: {
						DspID: "ID",
					},
					headers: [
						{ text: "ID", value: "DspID" },
						{ text: "Lugar Muestra", value: "SplName" },
						{ text: "Desinfectante Usado", value: "TypeDisinfectantName" },
						{text: "Cod Pallet" , value:"LlpID", width: "70", align:"center" },
						{ text: "Concentracion inicial (ppm)", value: "DspInitialConcentration" },
						{ text: "Repotenciación (ml)", value: "DspRepowering" },
						{ text: "Concentración final (ppm)", value: "DspFinalConcentration" },
						{ text: "Tiempo de inmersión (min)", value: "DspDiveTime" },
						{ text: "Acción correctiva/Observaciones", value: "TypeCorrelativeActionName" },
						{ text: "Nivel de agua en tina", value: "TypeWaterLevelName" }
					],
				},
			}
		},
		//created() {
		//}
	}
</script>